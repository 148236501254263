h1,
p {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.svg-wrapper {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

.carousel-container {
  position: relative;
  width: 100%;
  margin: 0 auto;
  display: flex;
 }

 .carousel-items-container {
  flex-grow: 1;
  overflow: hidden;
  padding: 0 10px;
 }
 
 .carousel-items {
  display: flex;
  transition: transform 0.5s ease-in-out;
  height: 100%;
  flex-wrap: nowrap;
  margin: 0 -5px; /* Offset the padding on the items */
  
 }
 
 .carousel-item {
  position: relative;
  top: 0;
  left: 0;
  flex-basis: 50%; /* Adjust the calculation to account for padding */
  flex-shrink: 0;
  padding: 0 5px; /* Add padding to the left and right of each item */
  box-sizing: border-box; /* Include padding in the element's total width */
 }
 
 /* Optional: Styling for the navigation buttons */
 button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  margin: 10px;
  z-index: 10;
 }
 
 button:hover {
  background-color: #0056b3;
 }
 
@layer components {
  .toast {
    transform: translateY(300%);
    transition: transform   0.3s ease-in-out;
  }
  .toast-active {
    transform: translateY(0);
  }
}

.custom-shape-divider-bottom-1700511623 {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1700511623 svg {
  position: relative;
  display: block;
  width: calc(203% + 1.3px);
  height: 142px;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1700511623 .shape-fill {
  fill: #374151;
}

/* Ensure container is responsive */
.image-container {
  width: 100%; /* Container should take full width of its parent */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

/* Style for the image itself */
.image-container img {
  max-width: 100%; /* Ensures the image scales to the width of the container */
  height: auto; /* Maintains aspect ratio */
}

@tailwind base;
@tailwind components;
@tailwind utilities;
